<template>
  <div>
    <v-navigation-drawer
      color="#FFF9C4"
      v-model="drawer"
      app
      :clipped="$vuetify.breakpoint.lgAndUp"
      left
      dark
    >
      <v-list dense nav>
        <v-subheader class="black--text pb-9 pt-7" style="font-size:17px;">เมนู</v-subheader>
        <v-list-item-group v-model="selectedItem" mandatory
        color="#ffa600">
          <v-list-item
            v-for="item in items"
            :key="item.title"
            @click.stop.prevent="linkto(item)"
            :disabled="item.disable"
          >
            <v-list-item-icon>
              <v-icon color="black">{{ item.icon }} </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="black--text"> {{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      :scroll-threshold="1"
      app
      class="elevation-0"
      color="#FFCA05"
      clipped-left
      outlined
    >   <!-- -------navbar icon for lg and up------- -->
       <v-app-bar-nav-icon v-if="$vuetify.breakpoint.lgAndUp" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <!-- -------navbar icon for md and sown------- -->
      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndDown" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <img :src="require('@/assets/img/logo-black.png')" width="150"/>
         </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-divider inset vertical color="black" ></v-divider>

      <v-menu
        class="hidden-sm-and-down"
        :close-on-content-click="false"
        offset-y
        bottom
        open-on-hover
        transition="slide-y-transition"
        max-width="300"
      >
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">
            <v-icon  large class="mr-2">mdi-account-circle-outline</v-icon>
            <span class="hidden-sm-and-down">&nbsp; {{ _Fullname }}</span>
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list dense>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon large color="black" x-large>mdi-account-circle-outline</v-icon>
              </v-list-item-avatar>

              <v-list-item-content class="text-left">
                <v-list-item-title class="pb-1">{{ _Fullname }}</v-list-item-title>
                <v-list-item-subtitle style="font-size:10px;">{{ _Companyth }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ _TaxId }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <!-- <v-divider></v-divider> -->
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="red" outlined to="/logout">
              <v-icon class="mr-2">mdi-logout-variant</v-icon>
              ออกจากระบบ
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: function () {
    return {
      selectedItem: 1,
      drawer: null,
      items: [
        {
          no: 1,
          title: 'รายงานการรับชำระภาษีหัก ณ ที่จ่าย',
          icon: 'mdi-remote-desktop',
          path: '/total_report',
          active: false,
          disable: false,
          role: [1, 2]
        },
        {
          no: 2,
          title: 'รายการรับชำระที่ไม่พบข้อมูล',
          icon: 'mdi-monitor-lock',
          path: '/report_sap',
          active: false,
          disable: false,
          role: [1, 2]
        },
        {
          no: 3,
          title: 'รายการรอชำระ',
          icon: 'mdi-chart-box-plus-outline ',
          path: '/report_ibs',
          active: false,
          disable: false,
          role: [1, 2]
        },
        {
          no: 4,
          title: 'รายการข้อมูลยกเว้น',
          icon: 'mdi-card-remove-outline',
          path: '/except_information',
          active: false,
          disable: false,
          role: [1, 2]
        }
        // {
        //   no: 5,
        //   title: 'รายการข้อมูลยกเว้นออกคืน',
        //   icon: 'mdi-clipboard-remove-outline',
        //   path: '/except_information_returned',
        //   active: false,
        //   disable: false,
        //   role: [1, 2]
        // }
      ],
      rightmenu: [
        {
          icon: 'mdi-logout-variant',
          group: false,
          list: [],
          text: 'ออกจากระบบ',
          permission: [],
          to: '/logout'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['getter_dhl_profile']),
    _Fullname: {
      get () {
        // eslint-disable-next-line dot-notation
        if ('currentuser' in this.getter_dhl_profile) {
          // eslint-disable-next-line dot-notation
          return (this.getter_dhl_profile['currentuser']['account_title_th'] + this.getter_dhl_profile['currentuser']['first_name_th'] + ' ' + this.getter_dhl_profile['currentuser']['last_name_th'])
        } else {
          return '-'
        }
      }
    },
    _Companyth: {
      get () {
        return (this.getter_dhl_profile.first_name_th)
      }
    },
    _TaxId: {
      get () {
        return (this.getter_dhl_profile.id_card_num)
      }
    }
  },
  mounted () {
    if (this.$route.path === '/total_report') {
      this.selectedItem = 0
    } else if (this.$route.path === '/report_sap') {
      this.selectedItem = 1
    } else if (this.$route.path === '/report_ibs') {
      this.selectedItem = 2
    } else if (this.$route.path === '/tax_form') {
      this.selectedItem = 3
    } else if (this.$route.path === '/except_information') {
      this.selectedItem = 3
    }
    // } else if (this.$route.path === '/except_information_returned') {
    //   this.selectedItem = 4
    // }
  },
  methods: {
    linkto: function (_item) {
      // this.drawer = false,
      this.$router.push(_item.path)
    }
  }
}
</script>
